import './Notificator.css';
import { AumeeApp } from '../AumeeApp';
import { connect } from 'react-redux';
import { setAppLoading } from '../../store/slices/app-loading';
import { setAppRefreshData } from '../../store/slices/app-refresh-data';
import assistantSteps from './notificator.assistant';
import {
    ALL_LOCALES,
    AbsoluteIconButton,
    Button,
    Container,
    Direction,
    EditableElement,
    EditableList,
    InputText,
    Locales,
    Select,
    SelectLocale,
    Spacer,
    Switch,
    TextArea,
} from '@dyrloz/aumee-design';
import {
    AumeeAccounts,
    AumeeClient,
    AumeeTranslator,
    AumeeURL,
} from '@dyrloz/aumee-client';
import parse from 'html-react-parser';

type NotificatorState = {
    appInfoVars: {
        [key: string]: string;
    };
    templates: TemplateSummary[];
    templateSelected: Template;

    templateName: string;
    templateNamePattern: string;
    isEditingTemplateName: boolean;
    localeSelected: Locales;

    connectedUserProfile: UserProfile;

    mailTitle: string;
    mailContent: string;

    isInputsLoading: boolean;
    isContentLoading: boolean;
    isRenderingMail: boolean;
    isSendingMail: boolean;
    isMailCopied: boolean;
};

type TemplateSummary = {
    id: string;
    name: string;
};

type Template = TemplateSummary & {
    vars: string[];
    use_app_color: boolean;
    use_header_footer: boolean;
    use_for_marketor: boolean;
    locales_mail: {
        locale: Locales;
        title: string;
        content: string;
    }[];
};

type UserProfile = {
    email: string;
    locale: string;
};

class Notificator extends AumeeApp<NotificatorState> {
    constructor(props: any) {
        super('Notificator', props, assistantSteps);

        const quertyParameters = AumeeURL.extractURLParameters();

        this.state = {
            appInfoVars: {},
            templates: [],
            templateSelected: null,

            templateName: '',
            templateNamePattern: null,
            isEditingTemplateName: false,
            localeSelected: (quertyParameters.locale as Locales) || 'en',
            connectedUserProfile: null,

            mailTitle: '',
            mailContent: '',

            isInputsLoading: false,
            isContentLoading: false,
            isRenderingMail: false,
            isSendingMail: false,
            isMailCopied: false,
        };

        this.onAddTemplate = this.onAddTemplate.bind(this);
        this.onTemplateSelected = this.onTemplateSelected.bind(this);
        this.onTemplateNameChanged = this.onTemplateNameChanged.bind(this);
        this.onLocaleSelected = this.onLocaleSelected.bind(this);
        this.onTemplateBooleanChanged =
            this.onTemplateBooleanChanged.bind(this);
        this.onDeleteTemplate = this.onDeleteTemplate.bind(this);

        this.onCopyMail = this.onCopyMail.bind(this);
        this.onResetMail = this.onResetMail.bind(this);
        this.onSaveMail = this.onSaveMail.bind(this);
        this.onSendMail = this.onSendMail.bind(this);

        this.onVarAdded = this.onVarAdded.bind(this);
        this.onVarUpdated = this.onVarUpdated.bind(this);
        this.onVarDeleted = this.onVarDeleted.bind(this);
        this.onVarUsed = this.onVarUsed.bind(this);

        this.props.dispatch(
            setAppLoading({
                appLoading: this.appName,
            }),
        );
        this.props.dispatch(
            setAppRefreshData({
                appRefreshData: this.appName,
                appId: this.props.appId,
            }),
        );
    }

    protected async refreshAppData() {
        const appInfo = await AumeeAccounts.getAppInfo(this.props.appId, false);

        const appInfoVars: { [key: string]: string } = {};
        appInfoVars['default.app.account_name'] = appInfo.account_name;
        appInfoVars['default.app.icon'] = appInfo.icon;
        appInfoVars['default.app.url'] = appInfo.url;
        appInfoVars['default.app.colors.primary'] =
            appInfo.colors.light.primary.value;
        appInfoVars['default.app.colors.secondary'] =
            appInfo.colors.light.secondary.value;
        appInfoVars['default.app.colors.tertiary'] =
            appInfo.colors.light.tertiary.value;
        appInfoVars['default.app.colors.alert'] =
            appInfo.colors.light.alert.value;
        appInfoVars['default.app.colors.warning'] =
            appInfo.colors.light.warning.value;

        this.setState({
            appInfoVars,
        });

        await this.refreshTemplates();
        this.props.dispatch(setAppLoading({ appLoading: '' }));
    }

    protected async refreshTemplates() {
        const templates: TemplateSummary[] = await AumeeClient.get(
            '/templates?app_id=' + this.props.appId,
        )
            .then((res) => res.json())
            .then((res) => res.data);

        templates.sort((a, b) => (a.name > b.name ? 1 : -1));

        const templateId =
            templates.find(
                (t) => t.id === AumeeURL.extractURLParameters().template_id,
            )?.id || templates[0]?.id;

        if (templateId) {
            this.setState({
                templates,
                templateSelected: null,
            });
            const templateSelected = await this.selectTemplate(templateId);
            this.onResetMail(templateSelected);
        } else {
            this.setState({
                templates,
                templateSelected: null,
                isInputsLoading: false,
                isContentLoading: false,
            });
        }
    }

    private async updateTemplate(
        update: any,
        refreshInputs: boolean,
        refreshContent: boolean,
        refreshAllTemplates = false,
    ) {
        this.setState({
            isInputsLoading: refreshInputs,
            isContentLoading: refreshContent,
        });

        const updateDone = await AumeeClient.patch(
            '/templates/' + this.state.templateSelected.id,
            update,
            false,
            null,
        ).catch((error) => {
            if (error.status === 409) {
                this.setState({
                    templateNamePattern: '-/-',
                    isInputsLoading: false,
                    isContentLoading: false,
                });
            }
            return false;
        });

        if (updateDone) {
            if (!refreshAllTemplates)
                this.selectTemplate(this.state.templateSelected.id);
            else {
                this.refreshTemplates();
            }
        }
    }

    private async selectTemplate(id: string) {
        // 404 ?
        AumeeURL.addQueryParameterToURL('template_id=' + id);

        const templateSelected: Template = await AumeeClient.get(
            '/templates/' + id,
        )
            .then((res) => res.json())
            .then((res) => res.data);

        this.setState({
            templateSelected,
            templateName: templateSelected.name,
            isInputsLoading: false,
            isContentLoading: false,
            isEditingTemplateName: false,
        });

        const localeMail = templateSelected.locales_mail.find(
            (lm) => lm.locale === this.state.localeSelected,
        );

        if (!localeMail) this.onLocaleSelected('en', templateSelected);

        return templateSelected;
    }

    private replaceAppInfo(content: string) {
        if (
            this.state.templateSelected.name === 'header' ||
            this.state.templateSelected.name === 'footer'
        ) {
            const appInfoVars = this.state.templateSelected.vars.filter(
                (v) =>
                    v.indexOf('default.app.') === 0 &&
                    v.indexOf('default.app.colors') < 0,
            );

            for (const infoVar of appInfoVars) {
                content = content.replace(
                    new RegExp('\\${' + infoVar + '}', 'g'),
                    this.state.appInfoVars[infoVar],
                );
            }

            if (this.state.templateSelected.name === 'header') {
                content = content.replace(
                    new RegExp('\\${default.mail.title}', 'g'),
                    this.state.mailTitle,
                );
            }
        }

        if (!this.state.templateSelected.use_app_color) return content;

        const appColorsVars = this.state.templateSelected.vars.filter(
            (v) => v.indexOf('default.app.colors.') === 0,
        );

        for (const colorVar of appColorsVars) {
            content = content.replace(
                new RegExp('\\${' + colorVar + '}', 'g'),
                this.state.appInfoVars[colorVar],
            );
        }

        return content;
    }

    private replaceNewLine(content: string) {
        return content
            .replace(/>\n/g, '>\r')
            .replace(/\n/g, '<br>')
            .replace(/>\r/g, '>\n');
    }

    private async onAddTemplate() {
        this.setState({
            isInputsLoading: true,
            isContentLoading: true,
        });

        const templateId = await AumeeClient.post('/templates', {
            app_id: this.props.appId,
            name: 'template',
        })
            .then((res) => res.json())
            .then((res) => res.data.template_id);

        AumeeURL.addQueryParameterToURL('template_id=' + templateId);
        this.refreshTemplates();
    }

    private async onTemplateSelected(id: string) {
        this.setState({
            isInputsLoading: true,
            isContentLoading: true,
        });

        const templateSelected = await this.selectTemplate(id);
        if (
            templateSelected.locales_mail.find(
                (lm) => lm.locale === this.state.localeSelected,
            )
        )
            this.onResetMail(templateSelected);
    }

    private onTemplateNameChanged(templateName: string) {
        this.setState({ templateName, templateNamePattern: null });
    }

    private onLocaleSelected(
        locale: string,
        templateSelected = this.state.templateSelected,
    ) {
        AumeeURL.addQueryParameterToURL('locale=' + locale);
        this.onResetMail(templateSelected, locale as Locales);
        this.setState({
            localeSelected: locale as Locales,
        });
    }

    private async onTemplateBooleanChanged(
        attribute: string,
        checked: boolean,
    ) {
        const update = {
            [attribute]: checked,
        };

        this.updateTemplate(update, true, true);
    }

    private async onDeleteTemplate() {
        this.setState({
            isInputsLoading: true,
            isContentLoading: true,
        });

        await AumeeClient.delete(
            '/templates/' + this.state.templateSelected.id,
        );

        AumeeURL.removeQueryParameterFromURL('template_id');
        this.refreshTemplates();
    }

    private onCopyMail() {
        window.navigator.clipboard.writeText(
            this.replaceNewLine(this.state.mailContent),
        );
        this.setState({
            isMailCopied: true,
        });

        setTimeout(() => {
            this.setState({
                isMailCopied: false,
            });
        }, 5000);
    }

    private onResetMail(
        templateSelected = this.state.templateSelected,
        locale = this.state.localeSelected,
    ) {
        const localeMail = templateSelected.locales_mail.find(
            (lm) => lm.locale === locale,
        );

        this.setState({
            mailTitle: localeMail?.title || '',
            mailContent: localeMail?.content || '',
        });
    }

    private onSaveMail() {
        const update = {
            locales_mail: [
                {
                    locale: this.state.localeSelected,
                    title: this.state.mailTitle,
                    content: this.replaceNewLine(this.state.mailContent),
                },
            ],
        };

        this.updateTemplate(update, false, true);
    }

    private async onSendMail() {
        this.setState({
            isSendingMail: true,
        });
        let userProfile = this.state.connectedUserProfile;

        if (!userProfile)
            userProfile = await AumeeClient.get('/accounts/me/profile')
                .then((res) => res.json())
                .then((res) => res.data);

        const mailData: { [key: string]: string } = {};
        this.state.templateSelected.vars
            .filter((v) => v.indexOf('default.') !== 0)
            .forEach((v) => {
                mailData[v] = v.toUpperCase();
            });

        await AumeeClient.post(
            '/mails/templates/' + this.state.templateSelected.id,
            {
                destinator: userProfile.email,
                locale: this.state.localeSelected,
                mail_data: mailData,
            },
        );

        this.setState({
            connectedUserProfile: userProfile,
            isSendingMail: false,
        });
    }

    private onVarAdded(newElement: string) {
        this.updateTemplate(
            { vars: this.state.templateSelected.vars.concat([newElement]) },
            false,
            false,
        );
    }

    private onVarUpdated(oldElement: string, newElement: string) {
        const vars = this.state.templateSelected.vars.concat([newElement]);
        const update = {
            vars: vars.filter((v) => v !== oldElement),
        };

        this.updateTemplate(update, false, false);
    }

    private onVarDeleted(element: string) {
        const update = {
            vars: this.state.templateSelected.vars.filter((v) => v !== element),
        };

        this.updateTemplate(update, false, false);
    }

    private onVarUsed(element: string) {
        this.setState({
            mailContent: this.state.mailContent + ('${' + element + '}'),
        });
    }

    render() {
        const { isMobileScreen } = this.props;

        const {
            templates,
            templateSelected,
            templateName,
            templateNamePattern,
            isEditingTemplateName,
            localeSelected,

            mailTitle,
            mailContent,

            isInputsLoading,
            isContentLoading,
            isRenderingMail,
            isSendingMail,
            isMailCopied,
        } = this.state;

        const hasTemplateWritePermission =
            this.props.userPermissionsOnApp.includes('template.write');
        const hasMailTemplateSendPermission =
            this.props.userPermissionsOnApp.includes('mail_template.send');
        const isHeaderOrFooter = templateSelected
            ? templateSelected.name === 'header' ||
              templateSelected.name === 'footer'
            : false;
        const hasEditedMailTitleOrContent =
            templateSelected &&
            (this.replaceNewLine(mailContent) !==
                templateSelected.locales_mail.find(
                    (lm) => lm.locale === localeSelected,
                )?.content ||
                mailTitle !==
                    templateSelected.locales_mail.find(
                        (lm) => lm.locale === localeSelected,
                    )?.title);

        const orientation = isMobileScreen ? 'horizontal' : 'vertical';

        return (
            <div id={this.id} className="notificator page">
                {templateSelected && this.getAssistant()}
                {templates.length > 0 && (
                    <Container
                        fatherId={this.id}
                        usage="inputs"
                        isLoading={isInputsLoading}
                        padding="large"
                    >
                        <div className="aumee--flex-center inputs">
                            <div className="aumee--flex-center">
                                <Select
                                    fatherId={this.id}
                                    usage="template"
                                    size="long"
                                    withInput={true}
                                    isInfoTextDisplayed={true}
                                    placeholder={AumeeTranslator.translate(
                                        'notificator.template',
                                    )}
                                    values={templates.map((t) => {
                                        return { value: t.id, text: t.name };
                                    })}
                                    defaultValue={templateSelected?.id || ''}
                                    onValueChange={this.onTemplateSelected}
                                    optionsMaxSize="medium"
                                />
                                <Spacer direction="vertical" size="tiny" />
                                <Button
                                    fatherId={this.id}
                                    usage="new-template"
                                    icon="add"
                                    iconSize="24"
                                    onClick={this.onAddTemplate}
                                    disabled={
                                        !hasTemplateWritePermission ||
                                        !!this.state.templates.find(
                                            (t) => t.name === 'template',
                                        )
                                    }
                                />
                            </div>
                            {templateSelected && (
                                <Spacer direction="vertical" size="small" />
                            )}
                            {templateSelected && (
                                <EditableElement
                                    fatherId={this.id}
                                    usage="template-name"
                                    isDeleteDisplayed={false}
                                    isEditDisplayed={hasTemplateWritePermission}
                                    isEditing={isEditingTemplateName}
                                    onEdit={() =>
                                        this.setState({
                                            isEditingTemplateName: true,
                                        })
                                    }
                                    onCancelEdit={() =>
                                        this.setState({
                                            isEditingTemplateName: false,
                                            templateName: templateSelected.name,
                                        })
                                    }
                                    onValidateEdit={() => {
                                        if (
                                            this.state.templateName.match(
                                                '^[a-z._0-9]+$',
                                            )
                                        )
                                            this.updateTemplate(
                                                {
                                                    name: this.state
                                                        .templateName,
                                                },
                                                true,
                                                false,
                                                true,
                                            );
                                    }}
                                >
                                    <div>
                                        {!isEditingTemplateName && (
                                            <span
                                                id={
                                                    this.id +
                                                    '_span_template-name'
                                                }
                                            >
                                                {templateSelected.name}
                                            </span>
                                        )}
                                        {isEditingTemplateName && (
                                            <InputText
                                                fatherId={this.id}
                                                usage="template-name"
                                                size="medium"
                                                pattern={
                                                    templateNamePattern ||
                                                    '^[a-z._0-9]*$'
                                                }
                                                errorMessage={
                                                    templateNamePattern
                                                        ? AumeeTranslator.translate(
                                                              'common.already_exists',
                                                          )
                                                        : AumeeTranslator.translate(
                                                              'common.wrong_key_format',
                                                          )
                                                }
                                                placeholder={AumeeTranslator.translate(
                                                    'notificator.template_name',
                                                )}
                                                value={templateName}
                                                onValueChange={
                                                    this.onTemplateNameChanged
                                                }
                                            />
                                        )}
                                    </div>
                                </EditableElement>
                            )}
                            {templateSelected && (
                                <Spacer direction="vertical" size="small" />
                            )}
                            {templateSelected && (
                                <SelectLocale
                                    fatherId={this.id}
                                    locales={ALL_LOCALES}
                                    defaultValue={localeSelected}
                                    onValueChange={this.onLocaleSelected}
                                />
                            )}
                            {templateSelected && (
                                <Spacer direction="vertical" size="small" />
                            )}
                            {templateSelected && (
                                <Switch
                                    fatherId={this.id}
                                    usage="use-app-color"
                                    label={AumeeTranslator.translate(
                                        'notificator.template.use_app_color',
                                    )}
                                    checked={templateSelected.use_app_color}
                                    disabled={
                                        !hasTemplateWritePermission ||
                                        isHeaderOrFooter
                                    }
                                    onValueChange={(checked: boolean) =>
                                        this.onTemplateBooleanChanged(
                                            'use_app_color',
                                            checked,
                                        )
                                    }
                                />
                            )}
                            {templateSelected && (
                                <Spacer direction="vertical" size="small" />
                            )}
                            {templateSelected && (
                                <Switch
                                    fatherId={this.id}
                                    usage="use-header-footer"
                                    label={AumeeTranslator.translate(
                                        'notificator.template.use_header_footer',
                                    )}
                                    checked={templateSelected.use_header_footer}
                                    disabled={
                                        !hasTemplateWritePermission ||
                                        isHeaderOrFooter
                                    }
                                    onValueChange={(checked: boolean) =>
                                        this.onTemplateBooleanChanged(
                                            'use_header_footer',
                                            checked,
                                        )
                                    }
                                />
                            )}
                            {templateSelected && (
                                <Spacer direction="vertical" size="small" />
                            )}
                            {templateSelected && (
                                <Switch
                                    fatherId={this.id}
                                    usage="use-for-marketor"
                                    label={AumeeTranslator.translate(
                                        'notificator.template.use_for_marketor',
                                    )}
                                    checked={templateSelected.use_for_marketor}
                                    disabled={
                                        !hasTemplateWritePermission ||
                                        isHeaderOrFooter
                                    }
                                    onValueChange={(checked: boolean) =>
                                        this.onTemplateBooleanChanged(
                                            'use_for_marketor',
                                            checked,
                                        )
                                    }
                                />
                            )}
                            {templateSelected && hasTemplateWritePermission && (
                                <AbsoluteIconButton
                                    fatherId={this.id}
                                    usage="delete-template"
                                    icon="delete"
                                    verticalPosition="top"
                                    horizontalPosition="right"
                                    marginHorizontal="medium"
                                    marginVertical="small"
                                    onClick={this.onDeleteTemplate}
                                />
                            )}
                        </div>
                    </Container>
                )}
                {templates.length > 0 && <Spacer />}
                <Container
                    fatherId={this.id}
                    usage="locale-mail"
                    isLoading={isContentLoading}
                    padding="epic"
                >
                    <div className="aumee--flex-center locale-mail">
                        {templates.length === 0 &&
                            !hasTemplateWritePermission &&
                            AumeeTranslator.translate(
                                'notificator.template.not_found',
                            )}
                        {templates.length === 0 &&
                            hasTemplateWritePermission && (
                                <Button
                                    fatherId={this.id}
                                    usage="new-template"
                                    icon="add"
                                    iconSize="64"
                                    onClick={this.onAddTemplate}
                                />
                            )}
                        {templates.length > 0 && templateSelected && (
                            <div className="mail">
                                {(hasTemplateWritePermission ||
                                    hasMailTemplateSendPermission) && (
                                    <div className="actions">
                                        <Button
                                            fatherId={this.id}
                                            usage="copy"
                                            icon={
                                                isMailCopied
                                                    ? 'validate'
                                                    : 'copy'
                                            }
                                            style="underlined"
                                            onClick={this.onCopyMail}
                                        />
                                        {hasMailTemplateSendPermission &&
                                            !hasEditedMailTitleOrContent && (
                                                <div
                                                    className={`moving-icon ${
                                                        isSendingMail
                                                            ? 'fly'
                                                            : 'origin'
                                                    }`}
                                                >
                                                    <Button
                                                        fatherId={this.id}
                                                        usage="send-mail"
                                                        icon="email_send"
                                                        style="underlined"
                                                        onClick={
                                                            this.onSendMail
                                                        }
                                                        disabled={isSendingMail}
                                                    />
                                                </div>
                                            )}
                                        {hasTemplateWritePermission &&
                                            hasEditedMailTitleOrContent && (
                                                <Button
                                                    fatherId={this.id}
                                                    usage="save-mail"
                                                    icon="save"
                                                    style="underlined"
                                                    onClick={this.onSaveMail}
                                                />
                                            )}
                                        {hasTemplateWritePermission && (
                                            <Button
                                                fatherId={this.id}
                                                usage="render-mail"
                                                icon={
                                                    isRenderingMail
                                                        ? 'eyeSlash'
                                                        : 'eye'
                                                }
                                                style="underlined"
                                                onClick={() =>
                                                    this.setState({
                                                        isRenderingMail:
                                                            !this.state
                                                                .isRenderingMail,
                                                    })
                                                }
                                            />
                                        )}
                                        {hasTemplateWritePermission && (
                                            <Button
                                                fatherId={this.id}
                                                usage="reset-mail"
                                                icon="refresh"
                                                style="underlined"
                                                onClick={this.onResetMail}
                                            />
                                        )}
                                    </div>
                                )}
                                {hasTemplateWritePermission && (
                                    <Spacer size="tiny" />
                                )}
                                {(isRenderingMail ||
                                    !hasTemplateWritePermission) && (
                                    <div className="render">
                                        <span className="title">
                                            {mailTitle}
                                        </span>
                                        <Spacer />
                                        <div className="content">
                                            {parse(
                                                this.replaceNewLine(
                                                    this.replaceAppInfo(
                                                        mailContent,
                                                    ),
                                                ),
                                            )}
                                        </div>
                                    </div>
                                )}
                                {!isRenderingMail &&
                                    hasTemplateWritePermission && (
                                        <div className="modify">
                                            <InputText
                                                fatherId={this.id}
                                                usage="mail-title"
                                                placeholder={AumeeTranslator.translate(
                                                    'notificator.template.mail.title',
                                                )}
                                                value={mailTitle}
                                                size="fill"
                                                onValueChange={(value) =>
                                                    this.setState({
                                                        mailTitle: value,
                                                    })
                                                }
                                            />
                                            <Spacer size="tiny" />
                                            <TextArea
                                                fatherId={this.id}
                                                usage="mail-content"
                                                type="html"
                                                fillHeight={true}
                                                placeholder={AumeeTranslator.translate(
                                                    'notificator.template.mail.content',
                                                )}
                                                value={mailContent}
                                                size="fill"
                                                onValueChange={(value) =>
                                                    this.setState({
                                                        mailContent: value,
                                                    })
                                                }
                                            />
                                        </div>
                                    )}
                            </div>
                        )}
                        {templates.length > 0 &&
                            templateSelected &&
                            hasTemplateWritePermission && (
                                <Spacer
                                    direction={orientation}
                                    size={
                                        orientation === 'horizontal'
                                            ? 'tiny'
                                            : 'medium'
                                    }
                                />
                            )}
                        {templates.length > 0 &&
                            templateSelected &&
                            hasTemplateWritePermission && (
                                <div className="vars">
                                    <EditableList
                                        fatherId={this.id}
                                        usage="vars"
                                        label={AumeeTranslator.translate(
                                            'notificator.template.vars',
                                        )}
                                        list={templateSelected.vars}
                                        onElementAdded={this.onVarAdded}
                                        onElementUpdated={this.onVarUpdated}
                                        onElementDeleted={this.onVarDeleted}
                                        onDetails={this.onVarUsed}
                                        isDetailsUsed={true}
                                        patternError="^(?!default\.)[a-z._0-9]*$"
                                        patternErrorMessage={AumeeTranslator.translate(
                                            'common.wrong_key_format',
                                        )}
                                        isEditAllowed={!isHeaderOrFooter}
                                        elementMinLenght={1}
                                        errorMinLenght={AumeeTranslator.translate(
                                            'common.too_short',
                                        )}
                                        elementMaxLenght={30}
                                        errorMaxLenght={AumeeTranslator.translate(
                                            'common.too_long',
                                        )}
                                    />
                                </div>
                            )}
                    </div>
                </Container>
            </div>
        );
    }
}

export default connect()(Notificator);
