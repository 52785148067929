import './FollowYou.css';
import { AumeeApp } from '../AumeeApp';
import { connect } from 'react-redux';
import { setAppLoading } from '../../store/slices/app-loading';
import { setAppRefreshData } from '../../store/slices/app-refresh-data';
import {
    Button,
    Container,
    DataList,
    DateTimePicker,
    Loader,
    Spacer,
} from '@dyrloz/aumee-design';
import { AumeeClient, AumeeTranslator, AumeeURL } from '@dyrloz/aumee-client';
import Mermaid from '../../components/mermaid/Mermaid';
import { STATUS_TO_COLOR } from '../../utils/utils';

type FollowYouState = {
    filters: { [key: string]: string };
    followData: FollowData;
    chartCode: string;
    isDataLoading: boolean;
    isRefreshNeeded: boolean;
    isTraceIdRefreshed: boolean;
};

type FollowData = {
    trace_ids_by_status: {
        status_code: string;
        trace_ids: {
            trace_id: string;
            count: number;
        }[];
    }[];
    apps_status: {
        total: number;
        data: {
            app_id: string;
            app_name: string;
            status_codes: {
                [key: string]: number;
            };
        }[];
    };
    trace_logs: Log[];
};

type Log = {
    _id: string;
    app_id: string;
    date: Date;
    class_name: string;
    trace_id: string;
    event: string;
    type: string;
    data: any;
};

class FollowYou extends AumeeApp<FollowYouState> {
    constructor(props: any) {
        super('FollowYou', props);

        // GET VALUE FROM URL OR DEFAULT ONE, THEN SET IN URL
        const queryParameters = AumeeURL.extractURLParameters();

        const actualDate = new Date();

        const filters: { [key: string]: string } = {
            start_date:
                (queryParameters.start_date as string) ||
                new Date(
                    actualDate.getFullYear(),
                    actualDate.getMonth(),
                    actualDate.getDate(),
                ).toISOString(),
            end_date:
                (queryParameters.end_date as string) ||
                actualDate.toISOString(),
            trace_id: (queryParameters.trace_id as string) || 'all',
        };

        this.state = {
            filters,
            followData: null,
            chartCode: 'app["' + this.props.appId + '"];',
            isDataLoading: false,
            isRefreshNeeded: false,
            isTraceIdRefreshed: false,
        };

        for (const key in filters) {
            if (filters[key] !== 'all') {
                AumeeURL.addQueryParameterToURL(key + '=' + filters[key]);
            }
        }

        this.generateChartCode = this.generateChartCode.bind(this);
        this.onFilterChange = this.onFilterChange.bind(this);
        this.onReset = this.onReset.bind(this);

        this.props.dispatch(
            setAppLoading({
                appLoading: this.appName,
            }),
        );
        this.props.dispatch(
            setAppRefreshData({
                appRefreshData: this.appName,
                appId: this.props.appId,
            }),
        );
    }

    protected async refreshAppData(): Promise<void> {
        this.setState({
            isDataLoading: true,
        });
        const requestParameters = AumeeURL.getURLParametersQuery();
        if (
            requestParameters.indexOf('start_date') === -1 ||
            requestParameters.indexOf('end_date') === -1
        )
            return;

        const followData = await AumeeClient.get(
            '/logs/follow' + requestParameters,
        )
            .then((res) => res.json())
            .then((res) => res.data);

        if (requestParameters.indexOf('trace_id') >= 0) {
            followData.trace_logs = await this.refreshTraceLogs();
        }

        const chartCode = this.generateChartCode(followData);

        this.props.dispatch(setAppLoading({ appLoading: '' }));
        this.setState({
            followData,
            chartCode,
            isDataLoading: false,
            isRefreshNeeded: false,
        });
    }

    private async refreshTraceLogs(): Promise<Log[]> {
        const requestParameters = AumeeURL.getURLParametersQuery();

        const traceLogs = await AumeeClient.get(
            '/logs' +
                requestParameters.replace(/app_id=[a-z0-9]+&/g, '') +
                '&type=status&sort=desc',
        )
            .then((res) => res.json())
            .then((res) =>
                res.data.map((l: any) => {
                    return {
                        ...l,
                        app_id: `${l.app_id}_${
                            l.class_name === 'AumeeMiddleware'
                                ? 'front'
                                : 'back'
                        }`,
                        date: new Date(l.date),
                    };
                }),
            );

        return traceLogs;
    }

    private async refreshTraceId(traceId: string) {
        this.setState({
            isTraceIdRefreshed: true,
        });
        const followData = {
            ...this.state.followData,
            trace_logs: traceId !== 'all' ? await this.refreshTraceLogs() : [],
        };

        const chartCode = this.generateChartCode(followData);

        this.setState({
            followData,
            chartCode,
            isTraceIdRefreshed: false,
        });
    }

    private generateChartCode(followData: FollowData) {
        let chartCode = '';

        const isTraceDisplayed = followData.trace_logs.length > 0;

        followData.apps_status.data.sort((a, b) => {
            if (a.app_id === this.props.appId || b.app_name > a.app_name) {
                return -1;
            } else if (a.app_name > b.app_name) {
                return 1;
            }
            return 0;
        });

        let linkNumber = 0;
        for (const appStatusCode of followData.apps_status.data) {
            if (!isTraceDisplayed) {
                chartCode += `${appStatusCode.app_id}[${appStatusCode.app_name}];`;
                const sli =
                    (appStatusCode.status_codes['2XX'] || 0) /
                    ((appStatusCode.status_codes['2XX'] || 0) +
                        (appStatusCode.status_codes['5XX'] || 0));
                const isGreen = sli >= 0.999;

                chartCode += `class ${appStatusCode.app_id} app-${
                    isGreen ? '2xx' : '5xx'
                };`;
                for (const code in appStatusCode.status_codes) {
                    chartCode += `${appStatusCode.app_id} --- ${appStatusCode.app_id}-${code}((${appStatusCode.status_codes[code]}));`;
                    chartCode += `class ${
                        appStatusCode.app_id
                    }-${code} app-${code.toLocaleLowerCase()};`;
                    chartCode += `linkStyle ${linkNumber} stroke:${
                        STATUS_TO_COLOR[parseInt(code[0]) - 1]
                    };`;
                    linkNumber++;
                }
            } else {
                const tracesLog = followData.trace_logs.filter(
                    (tl) => tl.app_id.indexOf(appStatusCode.app_id) === 0,
                );
                if (tracesLog.length > 0) {
                    for (const traceLog of tracesLog) {
                        const appType = traceLog.app_id.split('_')[1];
                        chartCode += `${traceLog.app_id}${
                            appType === 'front' ? '(' : '[['
                        }${appStatusCode.app_name}${
                            appType === 'front' ? ')' : ']]'
                        };`;
                        chartCode += `class ${traceLog.app_id} app-${
                            traceLog.data.status_code.toString()[0]
                        }xx;`;
                    }
                }
            }
        }

        // ADD LINKS BETWEEN APPS
        if (isTraceDisplayed) {
            let beforePreviousLog = null;
            let previousLog = null;
            let logNumber = 0;
            for (const log of followData.trace_logs) {
                if (previousLog) {
                    const previousDate = new Date(
                        log.date.getFullYear(),
                        log.date.getMonth(),
                        log.date.getDay(),
                        log.date.getHours(),
                        log.date.getMinutes(),
                        log.date.getSeconds(),
                        log.date.getMilliseconds() - (log.data.time || 0),
                    );
                    const previousTime =
                        log.data.time !== undefined
                            ? `|${previousDate.toLocaleTimeString()}.${previousDate.getMilliseconds()}|`
                            : '';

                    const appIdToLink =
                        log.data.time !== undefined &&
                        previousLog.data.time !== undefined &&
                        beforePreviousLog &&
                        log.data.time > previousLog.data.time
                            ? beforePreviousLog.app_id
                            : previousLog.app_id;
                    chartCode += `${appIdToLink} ${
                        this.props.isMobileScreen ? '' : '--'
                    }-->${previousTime} ${log.app_id};`;

                    chartCode += `${log.app_id} ${
                        this.props.isMobileScreen ? '' : '--'
                    }-->|${log.date.toLocaleTimeString()}.${log.date.getMilliseconds()}| ${appIdToLink};`;

                    chartCode += `linkStyle ${(logNumber - 1) * 2 + 1} stroke:${
                        STATUS_TO_COLOR[log.data.status_code.toString()[0] - 1]
                    },color:${
                        STATUS_TO_COLOR[log.data.status_code.toString()[0] - 1]
                    };`;
                    beforePreviousLog = previousLog;
                }
                previousLog = log;
                logNumber++;
            }
        }

        return chartCode;
    }

    private onFilterChange(attribute: string, value: string) {
        if (value !== 'all') {
            AumeeURL.addQueryParameterToURL(attribute + '=' + value);
        } else {
            AumeeURL.removeQueryParameterFromURL(attribute);
        }
        const filters = {
            ...this.state.filters,
            [attribute]: value,
        };
        this.setState({
            filters,
            isRefreshNeeded:
                attribute !== 'trace_id' ? true : this.state.isRefreshNeeded,
        });

        if (attribute === 'trace_id') {
            this.refreshTraceId(value);
        }
    }

    private onReset() {
        const actualDate = new Date();

        const filters: { [key: string]: string } = {
            ...this.state.filters,
            start_date: new Date(
                actualDate.getFullYear(),
                actualDate.getMonth(),
                actualDate.getDate(),
            ).toISOString(),
            end_date: actualDate.toISOString(),
        };

        this.setState({
            filters,
            isRefreshNeeded: true,
        });

        for (const key in filters) {
            if (filters[key] !== 'all') {
                AumeeURL.addQueryParameterToURL(key + '=' + filters[key]);
            } else {
                AumeeURL.removeQueryParameterFromURL(key);
            }
        }
    }

    render() {
        const { isTabletScreen, isMobileScreen } = this.props;

        const {
            filters,
            followData,
            chartCode,

            isDataLoading,
            isRefreshNeeded,
            isTraceIdRefreshed,
        } = this.state;

        return (
            <div id={this.id} className="follow-you page">
                <Container fatherId={this.id} usage="inputs">
                    <div className="aumee--flex-center aumee--flex-column">
                        <div className="inputs aumee--flex-center">
                            <DateTimePicker
                                fatherId={this.id}
                                usage="start-date"
                                type="datetime-local"
                                step="milliseconds"
                                value={filters['start_date'] as string}
                                placeholder={AumeeTranslator.translate(
                                    'logs.start_date',
                                )}
                                onValueChange={(date: string) => {
                                    this.onFilterChange('start_date', date);
                                }}
                            />
                            <DateTimePicker
                                fatherId={this.id}
                                usage="end-date"
                                type="datetime-local"
                                step="milliseconds"
                                value={filters['end_date'] as string}
                                min={filters['start_date'] as string}
                                placeholder={AumeeTranslator.translate(
                                    'logs.end_date',
                                )}
                                onValueChange={(date: string) => {
                                    this.onFilterChange('end_date', date);
                                }}
                            />
                            <div className="aumee--flex-center">
                                <Button
                                    fatherId={this.id}
                                    usage="refresh"
                                    icon="refresh"
                                    iconSize="24"
                                    onClick={this.refreshAppData}
                                    disabled={!isRefreshNeeded}
                                />
                                <Spacer size="tiny" direction="vertical" />
                                <Button
                                    fatherId={this.id}
                                    usage="reset"
                                    icon="cross"
                                    iconSize="24"
                                    style="warning"
                                    onClick={this.onReset}
                                />
                            </div>
                        </div>
                    </div>
                </Container>
                <Spacer size={isTabletScreen ? 'small' : 'medium'} />
                <Container
                    fatherId={this.id}
                    usage="data"
                    padding="epic"
                    isLoading={isDataLoading}
                >
                    {followData && (
                        <div className="content aumee--flex-center">
                            {isTraceIdRefreshed && <Loader size="small" />}
                            {!isTraceIdRefreshed && (
                                <div className="render aumee--flex-center aumee--flex-column">
                                    {followData.trace_logs.length > 0 && (
                                        <span className="trace-date">
                                            {followData.trace_logs[0].date.toLocaleDateString()}
                                        </span>
                                    )}
                                    <Mermaid
                                        id="sre"
                                        chart={`flowchart ${
                                            isMobileScreen ||
                                            filters['trace_id'] !== 'all'
                                                ? 'TD'
                                                : 'LR'
                                        }; ${chartCode}`}
                                        isLoading={isTraceIdRefreshed}
                                    />
                                </div>
                            )}
                            <Spacer
                                direction={
                                    isMobileScreen ? 'horizontal' : 'vertical'
                                }
                                size={isMobileScreen ? 'small' : 'medium'}
                            />
                            <DataList
                                fatherId={this.id}
                                usage="trace-ids"
                                list={followData.trace_ids_by_status.reduce(
                                    (total, statusWithTraces) =>
                                        total.concat(
                                            statusWithTraces.trace_ids.map(
                                                (t) => {
                                                    return {
                                                        id: t,
                                                        display: t,
                                                        data: statusWithTraces.status_code.toLocaleLowerCase(),
                                                    };
                                                },
                                            ),
                                        ),

                                    [],
                                )}
                                valueList={
                                    filters.trace_id !== 'all'
                                        ? [{ id: filters.trace_id }]
                                        : []
                                }
                                onListChange={(ids) =>
                                    this.onFilterChange(
                                        'trace_id',
                                        ids[0]?.id || 'all',
                                    )
                                }
                                isSoloValue={true}
                                isDataUsedAsTag={true}
                                iconNotSelected="walking"
                                notFoundMessage={AumeeTranslator.translate(
                                    'common.not_found',
                                )}
                                searchPlaceholder={AumeeTranslator.translate(
                                    'common.search',
                                )}
                            />
                        </div>
                    )}
                </Container>
            </div>
        );
    }
}

export default connect()(FollowYou);
