import { AumeeAccounts, AumeeTranslator, AumeeURL } from '@dyrloz/aumee-client';
import './Menu.css';
import {
    AumeeComponent,
    Button,
    ButtonProfile,
    Select,
    Tooltip,
} from '@dyrloz/aumee-design';
import { connect } from 'react-redux';
import { setAppId } from '../../store/slices/app-id';
import { setUserPermissionsOnApp } from '../../store/slices/app-permissions';
import { AppDispatch, RootState, listenerMiddleware } from '../../store/store';
import { checkAppToRefresh } from '../../store/slices/check-app-to-refresh';
import { setAppRefreshData } from '../../store/slices/app-refresh-data';

interface IMenuProps {
    position: string;
    appsToSelect: AppToSelect[];

    dispatch: AppDispatch;
    appId: string;
    userPermissionsOnApp: string[];
}

type MenuState = {
    appDisplayed: AppToManage;
    isPermissionsLoading: boolean;
};

type AppToManage = {
    name: string;
    path: string;
    icon?: string;
    permission: string;
};

type AppToSelect = {
    value: string;
    text: string;
};

class Menu extends AumeeComponent<IMenuProps, MenuState> {
    // WARNING : the names in this list have to correspond to each AumeeApp name.
    private apps: AppToManage[] = [
        {
            name: 'NewApp',
            path: '/new',
            icon: 'plus',
            permission: 'manager.apps.write',
        },
        { name: 'AppInfo', path: '/', icon: 'aumee', permission: 'app.read' },
        {
            name: 'Accesses',
            path: '/accesses',
            icon: 'lock',
            permission: 'access.read',
        },
        {
            name: 'Translator',
            path: '/translator',
            icon: 'translate',
            permission: 'translations.read',
        },
        {
            name: 'Notificator',
            path: '/notificator',
            icon: 'email_open',
            permission: 'template.read',
        },
        {
            name: 'Marketor',
            path: '/marketor',
            icon: 'email_send',
            permission: 'mass_mails_template.send',
        },
        // {
        //     name: 'Communicator',
        //     path: '/communicator',
        //     icon: 'chat',
        //     permission: 'communications.read',
        // },
        {
            name: 'HeatMap',
            path: '/heatmap',
            icon: 'fire',
            permission: 'logs.read',
        },
        {
            name: 'Analyst',
            path: '/analyst',
            icon: 'data',
            permission: 'logs.read',
        },
        {
            name: 'FollowYou',
            path: '/follow',
            icon: 'walking',
            permission: 'logs.read',
        },
        {
            name: 'LogThisOut',
            path: '/logs',
            icon: 'file',
            permission: 'logs.read',
        },
    ];

    private homeApp: AppToManage = this.apps.find(
        (app) => app.name === 'AppInfo',
    );

    constructor(props: IMenuProps) {
        super('Menu', props);

        const appInPath = this.getAppInPath();
        const appIdInParameters = this.getAppIdInParameters();

        this.state = {
            appDisplayed: appInPath,
            isPermissionsLoading: appIdInParameters !== null,
        };

        this.onMenuItemClicked = this.onMenuItemClicked.bind(this);
        this.onAppSelected = this.onAppSelected.bind(this);

        listenerMiddleware.startListening({
            actionCreator: setUserPermissionsOnApp,
            effect: async (action) => {
                console.debug('Set app permissions');

                this.setState({
                    isPermissionsLoading: false,
                });

                if (
                    action.payload &&
                    !action.payload.includes(this.state.appDisplayed.permission)
                ) {
                    AumeeURL.navigate('Menu', '/', false, ['app_id']);
                    this.setState({
                        appDisplayed: this.homeApp,
                    });
                }
            },
        });

        listenerMiddleware.startListening({
            actionCreator: checkAppToRefresh,
            effect: async (action) => {
                const appToRefresh = this.getAppInPath().name;
                console.debug('App to refresh : ' + appToRefresh);

                this.props.dispatch(
                    setAppRefreshData({
                        appRefreshData: appToRefresh,
                        appId: action.payload.appId,
                    }),
                );
            },
        });

        let that = this;
        window.addEventListener('hashchange', function () {
            that.setState({
                appDisplayed: that.getAppInPath(),
            });
        });
    }

    getAppInPath() {
        return (
            this.apps.find(
                (app) =>
                    app.name !== 'AppInfo' &&
                    window.location.hash.match(app.path),
            ) || this.homeApp
        );
    }

    getAppIdInParameters() {
        let appIdInParameters = AumeeURL.extractURLParameters()
            .app_id as string;

        if (
            !this.props.appsToSelect
                .map((app) => app.value)
                .includes(appIdInParameters)
        ) {
            appIdInParameters = null;
            AumeeURL.navigate('Menu', '/new', false);
        }

        this.props.dispatch(
            setAppId({
                appId: appIdInParameters,
                avoidRefreshAppData: true,
            }),
        );

        return appIdInParameters;
    }

    onMenuItemClicked(app: AppToManage) {
        if (app.name !== this.state.appDisplayed.name) {
            this.setState({
                appDisplayed: app,
            });
            const parametersToKeep: string[] = [];

            if (this.props.appId && app.path !== '/new') {
                parametersToKeep.push('app_id');
                AumeeURL.addQueryParameterToURL('app_id=' + this.props.appId);
            }
            const appWithDate = ['/logs', '/analyst', '/follow', '/heatmap'];
            if (appWithDate.includes(app.path)) {
                parametersToKeep.push(
                    'start_date',
                    'end_date',
                    'session_id',
                    'type',
                    'event',
                    'trace_id',
                );
            }

            AumeeURL.navigate('Menu', app.path, false, parametersToKeep);
        }
    }

    onAppSelected(appId: string) {
        if (AumeeURL.getURL().indexOf('/new') === -1)
            AumeeURL.addQueryParameterToURL('app_id=' + appId);

        this.setState({
            isPermissionsLoading: true,
        });
        this.props.dispatch(setAppId({ appId }));
    }

    render() {
        const { position, appsToSelect, appId, userPermissionsOnApp } =
            this.props;
        const { appDisplayed, isPermissionsLoading } = this.state;

        return (
            <div id={this.id} className={`menu ${position}`}>
                <div className="mandatory-item">
                    <Select
                        fatherId={this.id}
                        usage="app-id"
                        defaultValue={appId}
                        size="fill"
                        placeholder={AumeeTranslator.translate(
                            'menu.select.app',
                        )}
                        values={appsToSelect}
                        onValueChange={this.onAppSelected}
                        optionsMaxSize="large"
                    />
                </div>
                <div
                    className={`menu-items ${
                        isPermissionsLoading ? 'invisible' : 'visible'
                    }`}
                >
                    {this.apps.reduce((total, app) => {
                        if (
                            userPermissionsOnApp &&
                            userPermissionsOnApp.includes(app.permission)
                        ) {
                            total.push(
                                <div className="tooltip" title={app.name}>
                                    <Button
                                        fatherId={this.id}
                                        usage={app.name.toLocaleLowerCase()}
                                        style={
                                            app.name === appDisplayed.name
                                                ? 'primary'
                                                : 'secondary'
                                        }
                                        label={
                                            app.icon
                                                ? ''
                                                : app.name[0].toUpperCase()
                                        }
                                        icon={app.icon}
                                        iconSize={
                                            position === 'left' ? '32' : '24'
                                        }
                                        onClick={() =>
                                            this.onMenuItemClicked(app)
                                        }
                                    />
                                </div>,
                            );
                        }

                        return total;
                    }, [])}
                </div>
                <div className="mandatory-item profile">
                    <Tooltip
                        label={AumeeTranslator.translate('profile')}
                        style="secondary"
                        position={
                            position === 'left' ? 'horizontal' : 'vertical'
                        }
                    >
                        <ButtonProfile
                            fatherId={this.id}
                            usage="profile"
                            profileIconUrl={AumeeAccounts.userIcon}
                            size={position === 'left' ? 'large' : 'medium'}
                            onClick={() =>
                                AumeeAccounts.redirectToProfile('Menu')
                            }
                        />
                    </Tooltip>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state: RootState) => ({
    appId: state.appId.value,
    userPermissionsOnApp: state.userPermissionsOnApp.value,
});

export default connect(mapStateToProps)(Menu);
