import * as React from 'react';
import './Manager.css';
import {
    AumeeTheme,
    AumeeClient,
    AumeeAccounts,
    AumeeAnalytics,
    AumeeTranslator,
    AumeeURL,
} from '@dyrloz/aumee-client';
import {
    IconFactory,
    Loader,
    SpanHTMLParser,
    selectTheme,
    setAllColors,
} from '@dyrloz/aumee-design';
import Menu from '../components/menu/Menu';
import Router from '../components/router/Router';
import { AppDispatch, RootState, listenerMiddleware } from '../store/store';
import { connect } from 'react-redux';
import { setAppLoading } from '../store/slices/app-loading';
import { setUserPermissionsOnApp } from '../store/slices/app-permissions';
import { DataIcon } from '../components/icons/data-icon/DataIcon';
import { isMobile, isTablet } from '../utils/utils';
import { FireIcon } from '../components/icons/fire-icon/FireIcon';

interface IManagerProps {
    dispatch: AppDispatch;
    appId: string;
    userPermissionsOnApp: string[];
}

type IManagerPropsState = {
    isLoading: boolean;
    menuPosition: 'left' | 'top';
    appsAccess: AppAccess[];
    isLoaderAnimationRunning: boolean;
    appNameLoading: string;
    isMobileScreen: boolean;
    isTabletScreen: boolean;
};

type AppAccess = {
    id: string;
    account_name: string;
};

class Manager extends React.Component<IManagerProps, IManagerPropsState> {
    private lastAppNameLoading: string = '';
    constructor(props: IManagerProps) {
        super(props);

        this.state = {
            isLoading: true,
            menuPosition: this.calculateMenuPosition(),
            appsAccess: [],
            isLoaderAnimationRunning: false,
            appNameLoading: '',
            isMobileScreen: isMobile(),
            isTabletScreen: isTablet(),
        };
        console.debug('Init Manager with nothing');

        AumeeAccounts.setOnAppInfoRetreived((appInfo: any) => {
            setAllColors(appInfo.colors);
        });

        AumeeAnalytics.setOnAumeeAnalyticsInit(async () => {
            return await AumeeClient.get(
                '/accounts/me/apps/manager.read',
                false,
                {
                    throwError: true,
                },
            )
                .then((res) => res.json())
                .then((res) => {
                    this.setState({
                        appsAccess: res.data.sort(
                            (a: AppAccess, b: AppAccess) =>
                                a.account_name > b.account_name ? 1 : -1,
                        ),
                    });
                    return res.data;
                })
                .catch(() => null);
        });

        AumeeTheme.setOnAumeeThemeInit((theme: 'light' | 'dark') =>
            selectTheme(theme),
        );
        AumeeClient.init(() => {
            console.debug('Init finalized');
            IconFactory.addIcon('data', DataIcon);
            IconFactory.addIcon('fire', FireIcon);

            if (!window.location.hash) {
                AumeeURL.navigate('Manager', '/', false, ['app_id']);
            }
            this.props.dispatch(setUserPermissionsOnApp([]));
            this.setState({ isLoading: false });
        });

        window.addEventListener('resize', () => {
            this.setState({ menuPosition: this.calculateMenuPosition() });
        });

        listenerMiddleware.startListening({
            actionCreator: setAppLoading,
            effect: async (action) => {
                if (action.payload.appLoading) {
                    this.setState({
                        isLoaderAnimationRunning: true,
                    });
                    this.lastAppNameLoading = action.payload.appLoading;
                }

                if (action.payload.appLoading !== null) {
                    this.setState({
                        appNameLoading: action.payload.appLoading,
                    });
                } else {
                    this.setState({
                        isLoaderAnimationRunning: true,
                        appNameLoading: this.lastAppNameLoading,
                    });
                }
            },
        });

        window.addEventListener('resize', () => {
            this.setState({
                isMobileScreen: isMobile(),
                isTabletScreen: isTablet(),
            });
        });
    }

    calculateMenuPosition() {
        return window.innerWidth >= window.innerHeight ? 'left' : 'top';
    }

    render() {
        const { appId, userPermissionsOnApp } = this.props;
        const {
            isLoading,
            menuPosition,
            appsAccess,
            isLoaderAnimationRunning,
            appNameLoading,
            isMobileScreen,
            isTabletScreen,
        } = this.state;

        const isLoaderDisplayed =
            appNameLoading !== '' ||
            isLoaderAnimationRunning ||
            ((!userPermissionsOnApp || userPermissionsOnApp.length < 3) &&
                AumeeURL.getURL().indexOf('/new') === -1);

        if (
            !appId &&
            AumeeURL.getURL().indexOf('/new') === -1 &&
            AumeeURL.getURL().indexOf('?app_id=') === -1
        ) {
            AumeeURL.navigate('Router', '/new', false);
        }

        return (
            <div className="aumee--theme-transition aumee--app app">
                {isLoading && <Loader size="large" />}
                {!isLoading && (
                    <div className={`manager ${menuPosition}`}>
                        <Menu
                            fatherId="manager"
                            position={menuPosition}
                            appsToSelect={appsAccess.map(
                                (appAccess: AppAccess) => {
                                    return {
                                        value: appAccess.id,
                                        text: appAccess.account_name,
                                    };
                                },
                            )}
                        />
                        <div className="content">
                            {isLoaderDisplayed && (
                                <div
                                    className={`app-loading ${
                                        appNameLoading === '' &&
                                        ((userPermissionsOnApp &&
                                            userPermissionsOnApp.length > 2) ||
                                            AumeeURL.getURL().indexOf('/new') >
                                                -1)
                                            ? 'invisible'
                                            : ''
                                    }`}
                                    onAnimationEnd={() => {
                                        this.setState({
                                            isLoaderAnimationRunning: false,
                                        });
                                    }}
                                >
                                    <Loader
                                        fatherId="manager"
                                        usage="app"
                                        size="medium"
                                        label={AumeeTranslator.translate(
                                            'loading',
                                        )}
                                    />
                                </div>
                            )}
                            {!isLoading &&
                                ((userPermissionsOnApp &&
                                    userPermissionsOnApp.length > 2) ||
                                    AumeeURL.getURL().indexOf('/new') > -1) &&
                                (appId ||
                                    AumeeURL.getURL().indexOf('/new') > -1) && (
                                    <Router
                                        appId={appId}
                                        menuPosition={menuPosition}
                                        isMobileScreen={isMobileScreen}
                                        isTabletScreen={isTabletScreen}
                                        userPermissionsOnApp={
                                            userPermissionsOnApp
                                        }
                                    />
                                )}
                        </div>
                    </div>
                )}
            </div>
        );
    }
}

const mapStateToProps = (state: RootState) => ({
    appId: state.appId.value,
    userPermissionsOnApp: state.userPermissionsOnApp.value,
});

export default connect(mapStateToProps)(Manager);
