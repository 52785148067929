import './HeatMap.css';
import { AumeeApp } from '../AumeeApp';
import { connect } from 'react-redux';
import { setAppLoading } from '../../store/slices/app-loading';
import { setAppRefreshData } from '../../store/slices/app-refresh-data';
import { AumeeClient, AumeeTranslator, AumeeURL } from '@dyrloz/aumee-client';
import {
    AbsoluteCoveringLoader,
    Button,
    Container,
    DataList,
    DateTimePicker,
    Spacer,
} from '@dyrloz/aumee-design';

type HeatMapState = {
    filters: { [key: string]: string };
    heatmapData: HeatMapLog[];
    sessionHeatmapData: HeatMapLog[];
    sessionIds: {
        id: string;
        display: string;
        data: number;
    }[];
    sessionStep: number;
    iframeZoom: number;
    iframePointer: {
        x: number;
        y: number;
    };
    isDataLoading: boolean;
    isRefreshNeeded: boolean;
    isIframeLoading: boolean;
};

type HeatMapLog = {
    _id: string;
    app_id: string;
    date: Date;
    class_name: string;
    trace_id: string;
    event: string;
    type: string;
    data: {
        url: string;
        screen_width: number;
        screen_height: number;
        event_type: string;
        event_x: number;
        event_y: number;
        scroll_element_id?: string;
        scroll_value?: number;
    };
};

class HeatMap extends AumeeApp<HeatMapState> {
    constructor(props: any) {
        super('HeatMap', props);

        // GET VALUE FROM URL OR DEFAULT ONE, THEN SET IN URL
        const queryParameters = AumeeURL.extractURLParameters();

        const actualDate = new Date();

        const filters: { [key: string]: string } = {
            start_date:
                (queryParameters.start_date as string) ||
                new Date(
                    actualDate.getFullYear(),
                    actualDate.getMonth(),
                    actualDate.getDate(),
                ).toISOString(),
            end_date:
                (queryParameters.end_date as string) ||
                actualDate.toISOString(),
            session_id: (queryParameters.session_id as string) || 'all',
        };

        this.state = {
            filters,
            heatmapData: [],
            sessionHeatmapData: [],
            sessionIds: [],
            sessionStep: 0,
            iframeZoom: 1,
            iframePointer: {
                x: 0,
                y: 0,
            },
            isDataLoading: false,
            isRefreshNeeded: false,
            isIframeLoading: false,
        };

        for (const key in filters) {
            if (filters[key] !== 'all') {
                AumeeURL.addQueryParameterToURL(key + '=' + filters[key]);
            }
        }

        this.onFilterChange = this.onFilterChange.bind(this);
        this.onReset = this.onReset.bind(this);
        this.calculateIframeZoom = this.calculateIframeZoom.bind(this);

        this.props.dispatch(
            setAppLoading({
                appLoading: this.appName,
            }),
        );
        this.props.dispatch(
            setAppRefreshData({
                appRefreshData: this.appName,
                appId: this.props.appId,
            }),
        );
    }

    protected async refreshAppData(): Promise<void> {
        this.setState({
            isDataLoading: true,
        });
        const requestParameters = AumeeURL.getURLParametersQuery();
        if (
            requestParameters.indexOf('start_date') === -1 ||
            requestParameters.indexOf('end_date') === -1
        )
            return;

        const heatmapData: HeatMapLog[] = await AumeeClient.get(
            '/logs' +
                requestParameters.replace(/&session_id=[a-zA-Z0-9]{12}/g, '') +
                '&type=analytics&event=heat_map_data',
        )
            .then((res) => res.json())
            .then((res) =>
                res.data.map((l: any) => {
                    return {
                        ...l,
                        date: new Date(l.date),
                    };
                }),
            );

        const sessionIds = Object.keys(
            heatmapData.reduce((object: { [key: string]: number }, log) => {
                object[log.trace_id.slice(0, 12)] = 1;
                return object;
            }, {}),
        ).map((s) => {
            return {
                id: s,
                display: s,
                data: heatmapData.filter((l) => l.trace_id.indexOf(s) === 0)
                    .length,
            };
        });

        let session = this.state.filters['session_id'];

        if (
            session !== 'all' &&
            !heatmapData.find((l) => l.trace_id.indexOf(session) === 0)
        ) {
            session = 'all';
        }

        this.setState({
            heatmapData,
            sessionIds,
            filters: {
                ...this.state.filters,
                session_id: session,
            },
            sessionHeatmapData: this.filterHeatmapDataBySession(
                session,
                heatmapData,
            ),
            sessionStep: 0,
            isDataLoading: false,
            isRefreshNeeded: false,
            isIframeLoading: false,
        });

        this.props.dispatch(setAppLoading({ appLoading: '' }));
    }

    private filterHeatmapDataBySession(
        session: string,
        heatmapData = this.state.heatmapData,
    ) {
        let sessionHeatmapData: HeatMapLog[] = [];
        if (session !== 'all') {
            sessionHeatmapData = heatmapData.filter(
                (l) => l.trace_id.indexOf(session) === 0,
            );
        }

        this.calculateIframeZoom();

        return sessionHeatmapData;
    }

    private onFilterChange(attribute: string, value: string) {
        if (value !== 'all') {
            AumeeURL.addQueryParameterToURL(attribute + '=' + value);
        } else {
            AumeeURL.removeQueryParameterFromURL(attribute);
        }
        const filters = {
            ...this.state.filters,
            [attribute]: value,
        };
        this.setState({
            filters,
            sessionStep:
                attribute === 'session_id' ? 0 : this.state.sessionStep,
            sessionHeatmapData:
                attribute === 'session_id'
                    ? this.filterHeatmapDataBySession(value)
                    : this.state.sessionHeatmapData,
            isRefreshNeeded:
                attribute !== 'session_id' ? true : this.state.isRefreshNeeded,
        });
    }

    private onReset() {
        const actualDate = new Date();

        const filters: { [key: string]: string } = {
            ...this.state.filters,
            start_date: new Date(
                actualDate.getFullYear(),
                actualDate.getMonth(),
                actualDate.getDate(),
            ).toISOString(),
            end_date: actualDate.toISOString(),
            session_id: 'all',
        };

        this.setState({
            filters,
            isRefreshNeeded: true,
        });

        for (const key in filters) {
            if (filters[key] !== 'all') {
                AumeeURL.addQueryParameterToURL(key + '=' + filters[key]);
            } else {
                AumeeURL.removeQueryParameterFromURL(key);
            }
        }
    }

    private onStepChange(change: number) {
        let newStep = this.state.sessionStep + change;

        if (newStep < 0) newStep = 0;
        if (newStep >= this.state.sessionHeatmapData.length)
            newStep = this.state.sessionHeatmapData.length - 1;

        this.setState({
            sessionStep: newStep,
        });

        this.calculateIframeZoom(100);
    }

    private calculateIframeZoom(timeout: number = 500) {
        this.setState({
            isIframeLoading: timeout > 100,
        });
        setTimeout(() => {
            const iframeRender = document.getElementById(
                'wrap-iframe',
            ) as HTMLIFrameElement;

            if (iframeRender && this.state.sessionHeatmapData.length > 0) {
                const stepData =
                    this.state.sessionHeatmapData[this.state.sessionStep].data;

                const iframeZoom =
                    iframeRender.offsetWidth / stepData.screen_width;

                this.setState({
                    iframeZoom,
                    isIframeLoading: false,
                    iframePointer: {
                        x: iframeZoom * stepData.event_x,
                        y: iframeZoom * stepData.event_y,
                    },
                });
            }
        }, timeout);
    }

    render() {
        const { isTabletScreen, isMobileScreen } = this.props;

        const {
            filters,
            heatmapData,
            sessionHeatmapData,
            sessionIds,
            sessionStep,
            iframeZoom,
            iframePointer,
            isDataLoading,
            isRefreshNeeded,
            isIframeLoading,
        } = this.state;

        const isStepOnMobile =
            sessionHeatmapData[sessionStep]?.data.screen_height >
            sessionHeatmapData[sessionStep]?.data.screen_width;

        return (
            <div id={this.id} className="heat-map page">
                <Container fatherId={this.id} usage="inputs">
                    <div className="aumee--flex-center aumee--flex-column">
                        <div className="inputs aumee--flex-center">
                            <DateTimePicker
                                fatherId={this.id}
                                usage="start-date"
                                type="datetime-local"
                                step="milliseconds"
                                value={filters['start_date'] as string}
                                placeholder={AumeeTranslator.translate(
                                    'logs.start_date',
                                )}
                                onValueChange={(date: string) => {
                                    this.onFilterChange('start_date', date);
                                }}
                            />
                            <DateTimePicker
                                fatherId={this.id}
                                usage="end-date"
                                type="datetime-local"
                                step="milliseconds"
                                value={filters['end_date'] as string}
                                min={filters['start_date'] as string}
                                placeholder={AumeeTranslator.translate(
                                    'logs.end_date',
                                )}
                                onValueChange={(date: string) => {
                                    this.onFilterChange('end_date', date);
                                }}
                            />
                            <div className="aumee--flex-center">
                                <Button
                                    fatherId={this.id}
                                    usage="refresh"
                                    icon="refresh"
                                    iconSize="24"
                                    onClick={this.refreshAppData}
                                    disabled={!isRefreshNeeded}
                                />
                                <Spacer size="tiny" direction="vertical" />
                                <Button
                                    fatherId={this.id}
                                    usage="reset"
                                    icon="cross"
                                    iconSize="24"
                                    style="warning"
                                    onClick={this.onReset}
                                />
                            </div>
                        </div>
                    </div>
                </Container>
                <Spacer size={isTabletScreen ? 'small' : 'medium'} />
                <Container
                    fatherId={this.id}
                    usage="data"
                    padding="epic"
                    isLoading={isDataLoading}
                >
                    <div className="content aumee--flex-center">
                        <div className="render aumee--flex-center aumee--flex-column">
                            {sessionHeatmapData.length > 0 && (
                                <div className="session aumee--flex-center aumee--flex-column">
                                    <div className="steps aumee--flex-center">
                                        <Button
                                            fatherId={this.id}
                                            usage="session-previous-step"
                                            icon="chevron"
                                            iconRotation={90}
                                            disabled={sessionStep === 0}
                                            onClick={() =>
                                                this.onStepChange(-1)
                                            }
                                        />
                                        <Spacer
                                            size="small"
                                            direction="vertical"
                                        />
                                        <span>
                                            {sessionStep + 1} /{' '}
                                            {sessionHeatmapData.length}
                                        </span>
                                        <Spacer
                                            size="small"
                                            direction="vertical"
                                        />
                                        <Button
                                            fatherId={this.id}
                                            usage="session-next-step"
                                            icon="chevron"
                                            iconRotation={270}
                                            disabled={
                                                sessionStep ===
                                                sessionHeatmapData.length - 1
                                            }
                                            onClick={() => this.onStepChange(1)}
                                        />
                                    </div>
                                    <Spacer
                                        size="small"
                                        direction="horizontal"
                                    />
                                    <div className="log-data">
                                        <span>
                                            <strong>
                                                {AumeeTranslator.translate(
                                                    'common.date',
                                                )}{' '}
                                                :{' '}
                                            </strong>
                                            {sessionHeatmapData[
                                                sessionStep
                                            ].date
                                                .toISOString()
                                                .replace(/[TZ]/g, ' ')}
                                        </span>
                                        <span>
                                            <strong>URL : </strong>
                                            {
                                                sessionHeatmapData[
                                                    sessionStep
                                                ].data.url.split('?')[0]
                                            }
                                        </span>
                                        <span>
                                            <strong>Screen : </strong>
                                            {
                                                sessionHeatmapData[sessionStep]
                                                    .data.screen_width
                                            }{' '}
                                            /{' '}
                                            {
                                                sessionHeatmapData[sessionStep]
                                                    .data.screen_height
                                            }
                                        </span>
                                        <span>
                                            <strong>Scroll : </strong>
                                            {
                                                sessionHeatmapData[sessionStep]
                                                    .data.scroll_value
                                            }
                                        </span>
                                        <span>
                                            <strong>Event : </strong>
                                            {
                                                sessionHeatmapData[sessionStep]
                                                    .data.event_x
                                            }{' '}
                                            /{' '}
                                            {
                                                sessionHeatmapData[sessionStep]
                                                    .data.event_y
                                            }
                                        </span>
                                    </div>
                                </div>
                            )}
                            {sessionHeatmapData.length === 0 && (
                                <div>No display yet</div>
                            )}
                            <Spacer size="small" direction="horizontal" />
                            <div className="container-iframe aumee--flex-center">
                                {isIframeLoading && (
                                    <AbsoluteCoveringLoader
                                        isBackgroundAnimated={false}
                                    />
                                )}
                                <div
                                    id="wrap-iframe"
                                    className="wrap-iframe"
                                    style={{
                                        width: isStepOnMobile
                                            ? 'auto'
                                            : 'calc(100% - 10px)',
                                        height: isStepOnMobile
                                            ? 'calc(100% - 10px)'
                                            : 'auto',
                                        maxWidth:
                                            (!isStepOnMobile &&
                                                (1200 /
                                                    sessionHeatmapData[
                                                        sessionStep
                                                    ]?.data.screen_width) *
                                                    sessionHeatmapData[
                                                        sessionStep
                                                    ]?.data.screen_height >
                                                    600) ||
                                            (isStepOnMobile &&
                                                sessionHeatmapData[sessionStep]
                                                    ?.data.screen_height > 600)
                                                ? (600 /
                                                      sessionHeatmapData[
                                                          sessionStep
                                                      ]?.data.screen_height) *
                                                  sessionHeatmapData[
                                                      sessionStep
                                                  ]?.data.screen_width
                                                : 1200,
                                        minHeight: isStepOnMobile ? 160 : 0,
                                    }}
                                >
                                    <iframe
                                        id="iframe-render"
                                        src={
                                            sessionHeatmapData[
                                                sessionStep
                                            ]?.data.url.split('?')[0] +
                                            '?utm_source=incognito'
                                        }
                                        style={{
                                            aspectRatio:
                                                sessionHeatmapData[sessionStep]
                                                    ?.data.screen_width /
                                                sessionHeatmapData[sessionStep]
                                                    ?.data.screen_height,
                                            width: isStepOnMobile
                                                ? 'auto'
                                                : '100%',
                                            height: isStepOnMobile
                                                ? '100%'
                                                : 'auto',
                                            zoom: iframeZoom,
                                        }}
                                    />
                                    <div
                                        className="pointer"
                                        style={{
                                            top: iframePointer.y,
                                            left: iframePointer.x,
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                        <Spacer
                            direction={
                                isMobileScreen ? 'horizontal' : 'vertical'
                            }
                            size={isMobileScreen ? 'small' : 'medium'}
                        />
                        <DataList
                            fatherId={this.id}
                            usage="session-ids"
                            list={sessionIds}
                            valueList={
                                filters.session_id !== 'all'
                                    ? [{ id: filters.session_id }]
                                    : []
                            }
                            onListChange={(ids) =>
                                this.onFilterChange(
                                    'session_id',
                                    ids[0]?.id || 'all',
                                )
                            }
                            isSoloValue={true}
                            iconNotSelected="fire"
                            notFoundMessage={AumeeTranslator.translate(
                                'common.not_found',
                            )}
                            searchPlaceholder={AumeeTranslator.translate(
                                'common.search',
                            )}
                        />
                    </div>
                </Container>
            </div>
        );
    }
}

export default connect()(HeatMap);
