import mermaid from 'mermaid';
import { useEffect } from 'react';
import './Mermaid.css';

mermaid.initialize({
    securityLevel: 'loose',
});

const Mermaid: any = ({ chart, id }: { chart: string; id: string }) => {
    useEffect(() => {
        document.getElementById(id)?.removeAttribute('data-processed');
        mermaid.contentLoaded();
    }, [chart, id]);

    return (
        <div className="mermaid aumee--flex-center" id={id}>
            {chart}
        </div>
    );
};

export default Mermaid;
