import * as React from 'react';
import { Route, Routes } from 'react-router-dom';
import Translator from '../../apps/translator/Translator';
import AppInfo from '../../apps/app-info/AppInfo';
import NewApp from '../../apps/new-app/NewApp';
import Accesses from '../../apps/accesses/Accesses';
import Notificator from '../../apps/notificator/Notificator';
import Marketor from '../../apps/marketor/Marketor';
import Communicator from '../../apps/communicator/Communicator';
import LogThisOut from '../../apps/log-this-out/LogThisOut';
import FollowYou from '../../apps/follow-you/FollowYou';
import { AumeeURL } from '@dyrloz/aumee-client';
import Analyst from '../../apps/analyst/Analyst';
import HeatMap from '../../apps/heat-map/HeatMap';

interface IRouterProps {
    appId: string;
    menuPosition: 'left' | 'top';
    userPermissionsOnApp: string[];
    isMobileScreen: boolean;
    isTabletScreen: boolean;
}

type IRouterPropsState = {};

export default class Router extends React.Component<
    IRouterProps,
    IRouterPropsState
> {
    private apps = [
        {
            app: NewApp,
            path: '/new',
        },
        {
            app: Accesses,
            path: '/accesses',
        },
        {
            app: Translator,
            path: '/translator',
        },
        {
            app: Notificator,
            path: '/notificator',
        },
        {
            app: Marketor,
            path: '/marketor',
        },
        {
            app: Communicator,
            path: '/communicator',
        },
        {
            app: HeatMap,
            path: '/heatmap',
        },
        {
            app: LogThisOut,
            path: '/logs',
        },
        {
            app: Analyst,
            path: '/analyst',
        },
        {
            app: FollowYou,
            path: '/follow',
        },
        {
            app: AppInfo,
            path: '*',
        },
    ];

    constructor(props: IRouterProps) {
        super(props);

        this.state = {};
        console.debug('Init Router');
    }

    render() {
        const {
            appId,
            userPermissionsOnApp,
            menuPosition,
            isMobileScreen,
            isTabletScreen,
        } = this.props;

        return (
            <Routes>
                {this.apps.map((a) => {
                    const App: any = a.app;

                    return (
                        <Route
                            path={a.path}
                            element={
                                <App
                                    appId={appId}
                                    userPermissionsOnApp={userPermissionsOnApp}
                                    menuPosition={menuPosition}
                                    isMobileScreen={isMobileScreen}
                                    isTabletScreen={isTabletScreen}
                                />
                            }
                        />
                    );
                })}
            </Routes>
        );
    }
}
